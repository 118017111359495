export default {
    HOME: [
        {
            name: 'Ver Resumen de ventas',
            code: 'view-summary'
        },
        {
            name: 'Ver alerta de inventario',
            code: 'view-security-stock'
        },
        {
            name: 'Ver productos mas vendidos',
            code: 'view-top-products'
        },
        {
            name: 'Ver Utilidad vs Facturacion',
            code: 'view-utility-billing'
        },
        {
            name: 'Agregar sugerencias',
            code: 'send-suggestion'
        },
    ],
    POS: [
        {
            name: 'Ver registros de ventas',
            code: 'view-sales-registries'
        },
        {
            name: 'Abrir ventas directas y abiertas',
            code: 'open-direct-and-open-sales'
        },
        {
            name: 'Ver canales de ventas',
            code: 'view-sales-channels'
        },
        {
            name: 'Agregar canales de ventas',
            code: 'add-sales-channels'
        },
        {
            name: 'Editar canales de ventas',
            code: 'edit-sales-channels'
        },
        {
            name: 'Finalizar ventas',
            code: 'end-sales'
        },
        {
            name: 'Eliminar venta',
            code: 'delete-sale'
        },
        {
            name: 'Realizar devoluciones',
            code: 'edit-sale'
        },
        {
            name: 'Editar precio de venta en el paso 2 de finalización de ventas',
            code: 'edit-selling-price-in-pos'
        },
        {
            name: 'Ver disponibilidad de productos',
            code: 'view-availability'
        }
    ],
    INVENTORY: [
        {
            name: 'Ver inventario',
            code: 'view-inventory'
        },
        {
            name: 'Sacar de inventario',
            code: 'out-inventory'
        },
        {
            name: 'Convertir items',
            code: 'convert-items'
        },
        {
            name: 'Ver precio unitario y total de inventario',
            code: 'view-unit-price'
        },
        {
            name: 'Crear y editar items',
            code: 'edit-items'
        },
        {
            name: 'Eliminar items',
            code: 'delete-items'
        },
        {
            name: 'Editar y dividir lotes',
            code: 'edit-split-batches'
        },
        {
            name: 'Ver registros de inventario',
            code: 'view-inventory-registries'
        },
        {
            name: 'Ver productos combinados',
            code: 'view-combined-products'
        },
        {
            name: 'Crear y editar productos combinados',
            code: 'create-edit-combined-products'
        },
        {
            name: 'Eliminar productos combinados',
            code: 'delete-combined-products'
        }
    ],
    BANK_ACCOUNTS: [
        {
            name: 'Ver cuentas bancarias',
            code: 'view-bank-accounts'
        },
        {
            name: 'Agregar cuentas bancarias',
            code: 'add-bank-accounts'
        },
        {
            name: 'Eliminar cuentas bancarias',
            code: 'delete-bank-accounts'
        },
        {
            name: 'Transferir entre cuentas bancarias',
            code: 'transfer-between-bank-accounts'
        },
        {
            name: 'Fondear cuentas bancarias',
            code: 'fund-bank-accounts'
        },
        {
            name: 'Retirar de cuentas bancarias',
            code: 'withdraw-from-bank-accounts'
        },
        {
            name: 'Ver registros de cuentas bancarias',
            code: 'view-bank-account-registries'
        },
        {
            name: 'Ver cuentas pendientes',
            code: 'view-pending-accounts'
        },
        {
            name: 'Crear y editar cuentas pendientes',
            code: 'create-edit-pending-accounts'
        },
        {
            name: 'Eliminar registro bancario',
            code: 'delete-bank-account-registries'
        }
    ],
    COMMERCE: [
        {
            name: 'Ver configuracion',
            code: 'view-commerce'
        },
        {
            name: 'Crear y editar usuarios',
            code: 'create-and-edit-users'
        },
        {
            name: 'Editar métodos de inventario',
            code: 'edit-inventory-methods'
        },
        {
            name: 'Editar uso de IGTF e IVA',
            code: 'edit-use-igtf-iva'
        },
        {
            name: 'Editar datos de la organización',
            code: 'edit-organization-data'
        },
        {
            name: 'Editar tasa de cambio',
            code: 'edit-exchange-rate'
        },
        {
            name: 'Editar cargos extras',
            code: 'edit-extra-charges'
        },
        {
            name: 'Editar gastos fijos',
            code: 'edit-fixed-variable'
        },
        {
            name: 'Habilitar Estatus de venta',
            code: 'edit-status-POS'
        },
        {
            name: 'Crear y editar métodos de pago',
            code: 'create-edit-payment-methods'
        },
        {
            name: 'Crear y editar Personal',
            code: 'create-edit-staff'
        }
    ],
    FINANCIAL_SUMMARY: [
        {
            name: 'Ver resumen financiero',
            code: 'view-utility-loss'
        },
        {
            name: 'Editar resumen financiero',
            code: 'view-fixed-variable-expenses'
        },
        {
            name: 'Crear y editar Gastos fijos y variables',
            code: 'create-edit-fixes-variables-expenses'
        },
        {
            name: 'Ver y generar reportes',
            code: 'generate-reports'
        },
    ],
    MARKETING: [
        {
            name: 'Ver campañas',
            code: 'view-campaign'
        },
        {
            name: 'Ver clientes',
            code: 'view-clients'
        },
        {
            name: 'Crear y editar filtros',
            code: 'create-edit-filters'
        },
        {
            name: 'Crear y editar campañas',
            code: 'create-edit-campaign'
        },
    ],
}
