import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UserService } from '@modules/auth/services';
import { AnalyticsService } from '@common/services';
import { SideNavItems, SideNavSection } from '@modules/navigation/models';
import { NavigationService } from '@modules/navigation/services';
import { Subscription } from 'rxjs';
import { GoTo } from '@modules/dashboard/models/dashboard.model';
import Intercom from '@intercom/messenger-js-sdk';
import { take } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';

@Component({
    selector: 'sb-side-nav',
    templateUrl: './side-nav.component.html',
    styleUrls: ['side-nav.component.scss'],
})
export class SideNavComponent implements OnInit, OnDestroy {
    @Input() sidenavStyle!: string;
    @Input() sideNavItems!: SideNavItems;
    @Input() sideNavSections!: SideNavSection[];

    goTo: GoTo = {
        title: 'Nueva venta',
        link: '/pos/direct-sales'
    }

    subscription: Subscription = new Subscription();
    routeDataSubscription!: Subscription;

    constructor(public navigationService: NavigationService, public userService: UserService, public analyticsService: AnalyticsService) {}

    ngOnInit() {
        this.userService.user$.pipe(
            take(1)
        ).subscribe(user => {
            Intercom({
                app_id: environment.INTERCOM_APP_ID,
                user_id: user._id,
                name: `${user.name} ${user.lastName}`,
                email: user.email,
                phone: user.phone,
                custom_attributes: {
                    whatsapp_number: user.phone
                },
                company: {
                    company_id: user.tenantId,
                    name: user.tenantId,
                }
                // created_at: user.createdAt, // Uncomment if needed
            });
        });
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    posthogTrack() {
        this.analyticsService.analyticsCapture('sale_opened sale', {lugar: "sidenav-top"} )
    }
}
