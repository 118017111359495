import { DateRangeService } from './date-range.service';
import { UtilityService } from './utility.service';
import { ExtraServicesService } from './extraServices.service';
import { LoadingService } from './loading.service';
import { StaffService } from './staff.service';
import { OrderService } from './order.service';
import { AnalyticsService } from './analytics.service';

export const services = [UtilityService, DateRangeService, ExtraServicesService, LoadingService, StaffService, OrderService, AnalyticsService];

export * from './utility.service';
export * from './date-range.service';
export * from './extraServices.service';
export * from './loading.service';
export * from './staff.service';
export * from './order.service';
export * from './analytics.service';
