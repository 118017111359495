export class User {

    constructor(
        public _id: string,
        public email: string,
        public name: string,
        public lastName: string,
        public tenantId: string,
        private _token: string,
        private _tokenExpirationDate: Date,
        public roles: string[],
        public phone: string,
        public birthday: Date,
        public location: string,
        public enabled:boolean,
        public status:string,
        public permissions: any,
        public showNews: boolean,
        public instructionsNotToShow: string[],
        public salePreferences: any
    ) {}

    get token() {
        if(!this._tokenExpirationDate || new Date() > this._tokenExpirationDate) {
            return null;
        }
        return this._token;
    }

    get tokenExpirationDate() {
        return this._tokenExpirationDate;
    }

}
