import { Component, Input, OnInit, ElementRef, Renderer2, AfterViewInit } from '@angular/core';
import { DomSanitizer, SafeHtml, SafeResourceUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Step } from '@common/interfaces';
import { AnalyticsService } from '@common/services';
import { InstructionsService } from '@common/services/instructions.service';
import { UserService } from '@modules/auth/services';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'fina-instructions-modal',
  templateUrl: './instructions-modal.component.html',
  styleUrls: ['./instructions-modal.component.scss']
})
export class InstructionsModalComponent implements OnInit, AfterViewInit {
  @Input() videoUrl: string = '';
  @Input() modalTitle: string = '';
  @Input() modalIconTitle: string = '';
  @Input() instructions: Step[] = [];
  @Input() text: string = '';
  @Input() lock: boolean = false;
  @Input() instructionsSetName: string = '';
  safeVideoUrl: SafeResourceUrl;
  safeText: SafeHtml;
  doNotShowAgain: boolean = false;
  isLoading: boolean = true;
  showOverlay: boolean = true;

  constructor(
    private sanitizer: DomSanitizer,
    public activeModal: NgbActiveModal,
    private router: Router,
    private renderer: Renderer2,
    private el: ElementRef,
    public analyticsService: AnalyticsService,
    private instructionsService: InstructionsService,
    private userService: UserService,
  ) { }

  ngOnInit(): void {
    this.safeVideoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.getVideoUrl(this.videoUrl));
    this.safeText = this.sanitizeHtml(this.text);
  }

  ngAfterViewInit() {
    const button = this.el.nativeElement.querySelector('.btn-to-go');
    if (button) {
      const path = button.getAttribute('path');
      if (path) {
        this.renderer.listen(button, 'click', () => {
          this.goTo(path);
        });
      }
    }
  }

  goTo(path: string) {
    this.activeModal.close({ doNotShowAgain: this.doNotShowAgain });
    this.analyticsService.analyticsCapture('onboarding_navegar siguiente paso', {modulo: this.modalTitle, navegacion: path, noMostrarMas: this.doNotShowAgain})
    this.router.navigate([path]);
  }

  sanitizeUrl(url: string): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);;
  }

  sanitizeHtml(text: string): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustHtml(text);
  }

  ngOnDestroy() {
    // Clean up if necessary
  }

  onIframeLoad() {
    setTimeout(() => { this.isLoading = false; }, 1000);
  }

  onClickIframe() {
    this.showOverlay = false;
    this.analyticsService.analyticsCapture('onboarding_ver video', {modulo: this.modalTitle})
    this.instructionsService.doNotShowAgain(this.instructionsSetName).subscribe({
        next: () => {
            const user = this.userService.user$.getValue();
            user.instructionsNotToShow = [...user.instructionsNotToShow, this.instructionsSetName]
            this.userService.user = user
        },
        error: (err: any) => {
            console.error(err);
        }
    });
  }

  private getVideoUrl(url: string): string {
    if (!url) return '';
    const videoId = this.getYouTubeVideoId(url);
    return `https://www.youtube-nocookie.com/embed/${videoId}`;
  }

  private getYouTubeVideoId(url: string): string {
    if (!url) return '';

    // Check if it's a YouTube URL
    if (url.includes('youtube.com') || url.includes('youtu.be')) {
      // Handle different YouTube URL formats
      let videoId = '';

      if (url.includes('youtube.com/watch?v=')) {
        videoId = url.split('v=')[1];
      } else if (url.includes('youtu.be/')) {
        videoId = url.split('youtu.be/')[1];
      }

      // Remove any additional parameters
      const ampersandPosition = videoId.indexOf('&');
      if (ampersandPosition !== -1) {
        videoId = videoId.substring(0, ampersandPosition);
      }

      // Return embedded YouTube URL
      return videoId;
    }

    // Return original URL if it's not a YouTube URL
    return url;
  }
}
